(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("dicom-parser", [], factory);
	else if(typeof exports === 'object')
		exports["dicom-parser"] = factory();
	else
		root["dicomParser"] = factory();
})(window, function() {
return 